.oque-fazemos_container{
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 110vh;
    overflow: hidden;
    width: 100%;
}

.oque-fazemos_title{
    font-family: Karla;
    font-size: 3rem;
    font-weight: 800;
    line-height: 93.52px;
    text-align: center;
    color: white;
}

.oque-fazemos_content {
    display: flex;
    color: white;
}


/* Smartphones (retratos) */
@media (max-width: 360px) { 
    .oque-fazemos_container{    
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        height: min-content;
        width: 100%;
        padding: 0px 24px;
    }

    .oque-fazemos_title{
        font-family: Karla;
        font-size: 3rem;
        font-weight: 800;
        line-height: 50px;
        padding-inline: 0px 16px;
        text-align: center;
        color: white;
    }
    
    .oque-fazemos_content {
        display: flex;
        flex-direction: column;
        justify-content: start;
    }
    .oque-fazemos__item{
        display: flex;
        justify-content: start;
        align-items: center;
        font-size: 1.5rem;
        gap: 8px;
    }
    
    .oque-fazemos__imagem-especialistas {
        width: 100%;
    }
    
    .oque-fazemos__frase-de-efeito {
        width: 90vw;
        margin-top: 60px;
    }
    
    .oque-fazemos__imagem-personagens {
        width: 100%;
        margin-bottom: -40px;
    }
    
    .oque-fazemos__text-container {
        position: absolute;
    
        width: 100%;
        height: 100vh;
        top: 0;
        z-index: 2;
    }
    
    .oque-fazemos__titulo {
        color: var(--dark-blue, #002060);
        text-align: center;
        font-family: Karla;
        font-style: normal;
        font-weight: 800;
        font-size: 2.8rem;
    }
    
    .oque-fazemos__bloco-texto {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .oque-fazemos__negrito {
        color: #002060;
        font-weight: 700;
    }
    
    .oque-fazemos__numero {
        color: #002060;
        font-size: 58px;
        font-weight: 800;
        margin-right: 12px;
    }
    
    .oque-fazemos__texto {
        color: #365d81;
        font-size: 1.12rem;
        font-weight: 600;
        margin: 10px 0px 0px 0px;
    }

    .titulo-1 {
        font-size: 2.6em;
        margin-top: 0px;
    }


    .titulo-1 {
        font-size: 2.1em;
        margin-top: 0px;
    }

    .titulo-2{
        font-size: 2.3em;
    }
}

@media (min-width: 361px) and (max-width: 522px) {
    .oque-fazemos_container{    
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        height: min-content;
        width: 100%;
        padding: 0px 24px;
    }

    .oque-fazemos_title{
        font-family: Karla;
        font-size: 3rem;
        font-weight: 800;
        line-height: 50px;
        padding-inline: 0px 16px;
        text-align: center;
        color: white;
    }
    
    .oque-fazemos_content {
        display: flex;
        flex-direction: column;
        justify-content: start;
    }
    .oque-fazemos__item{
        display: flex;
        justify-content: start;
        align-items: center;
        font-size: 1.5rem;
        gap: 8px;
    }
    
    .oque-fazemos__imagem-especialistas {
        width: 100%;
    }
    
    .oque-fazemos__frase-de-efeito {
        width: 90vw;
        margin-top: 60px;
    }
    
    .oque-fazemos__imagem-personagens {
        width: 100%;
        margin-bottom: -40px;
    }
    
    .oque-fazemos__text-container {
        position: absolute;
    
        width: 100%;
        height: 100vh;
        top: 0;
        z-index: 2;
    }
    
    .oque-fazemos__titulo {
        color: var(--dark-blue, #002060);
        text-align: center;
        font-family: Karla;
        font-style: normal;
        font-weight: 800;
        font-size: 2.8rem;
    }
    
    .oque-fazemos__bloco-texto {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .oque-fazemos__negrito {
        color: #002060;
        font-weight: 700;
    }
    
    .oque-fazemos__numero {
        color: #002060;
        font-size: 58px;
        font-weight: 800;
        margin-right: 12px;
    }
    
    .oque-fazemos__texto {
        color: #365d81;
        font-size: 1.12rem;
        font-weight: 600;
        margin: 10px 0px 0px 0px;
    }

    .titulo-1 {
        font-size: 2.3em;
        margin-top: 0px;
    }

    .titulo-2 {
        font-size: 2.3em;
    }
}

/* Smartphones (paisagem) */
@media (min-width: 522px) and (max-width: 640px) {
    .oque-fazemos_container{    
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        height: min-content;
        width: 100%;
        padding: 48px;
    }

    .oque-fazemos_title{
        font-family: Karla;
        font-size: 3rem;
        font-weight: 800;
        line-height: 50px;
        padding-inline: 0px 16px;
        text-align: center;
        color: white;
    }
    
    .oque-fazemos_content {
        display: flex;
        flex-direction: column;
        justify-content: start;
    }
    .oque-fazemos__item{
        display: flex;
        justify-content: start;
        align-items: center;
        font-size: 1.5rem;
        gap: 8px;
    }
    
    .oque-fazemos__imagem-especialistas {
        width: 100%;
        visibility: collapse;
    }
    
    .oque-fazemos__frase-de-efeito {
        width: 100%;
    }
    
    .oque-fazemos__imagem-personagens {
        width: 100%;
        margin-bottom: -140px;
        visibility: collapse;
    }
    
    .oque-fazemos__imagem-alto-falante {
        height: 40%;
    }
    
    .oque-fazemos__text-container {
        position: absolute;
    
        width: 100%;
        height: 100vh;
        top: 0;
        z-index: 2;
    }
    
    .oque-fazemos__titulo {
        color: #002060;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #000;
        font-size: 58px;
        margin: 0px;
    }
    
    .oque-fazemos__bloco-texto {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .oque-fazemos__negrito {
        color: #002060;
        font-weight: 700;
    }
    
    .oque-fazemos__numero {
        color: #002060;
        font-size: 58px;
        font-weight: 800;
        margin-right: 12px;
    }
    
    .oque-fazemos__texto {
        color: #365d81;
        font-size: 28px;
        font-weight: 600;
        margin: 10px 0px 0px 0px;
    }
}

/* Tablets (retratos) */
@media (min-width: 641px) and (max-width: 768px) {
    .oque-fazemos_container{    
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        height: min-content;
        width: 100%;
        padding: 48px;
    }

    .oque-fazemos_title{
        font-family: Karla;
        font-size: 3rem;
        font-weight: 800;
        line-height: 50px;
        padding-inline: 0px 16px;
        text-align: center;
        color: white;
    }
    
    .oque-fazemos_content {
        display: flex;
        flex-direction: column;
        justify-content: start;
    }
    .oque-fazemos__item{
        display: flex;
        justify-content: start;
        align-items: center;
        font-size: 1.5rem;
        gap: 8px;
    }
    
    .oque-fazemos__imagem-especialistas {
        width: 100%;
        margin-left: -80px;
    }
    
    .oque-fazemos__frase-de-efeito {
        width: 100%;
    }
    
    .oque-fazemos__imagem-personagens {
        width: 100%;
        margin-bottom: -140px;
    }
    
    .oque-fazemos__imagem-alto-falante {
        height: 40%;
    }
    
    .oque-fazemos__text-container {
        position: absolute;
    
        width: 100%;
        height: 100vh;
        top: 0;
        z-index: 2;
    }
    
    .oque-fazemos__titulo {
        color: #002060;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #000;
        font-size: 58px;
        margin: 0px;
    }
    
    .oque-fazemos__bloco-texto {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .oque-fazemos__negrito {
        color: #002060;
        font-weight: 700;
    }
    
    .oque-fazemos__numero {
        color: #002060;
        font-size: 58px;
        font-weight: 800;
        margin-right: 12px;
    }
    
    .oque-fazemos__texto {
        color: #365d81;
        font-size: 28px;
        font-weight: 600;
        margin: 10px 0px 0px 0px;
    }

    .oque-fazemos__imagem-container {
        display: flex;
    }

    .titulo-2 {
        margin-top: 80px;
    }
}

/* Tablets (paisagem) */
@media (min-width: 769px) and (max-width: 1024px) {
    .oque-fazemos_container{    
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        height: min-content;
        width: 100%;
        padding: 48px;
    }

    .oque-fazemos_title{
        font-family: Karla;
        font-size: 3rem;
        font-weight: 800;
        line-height: 50px;
        padding-inline: 0px 16px;
        text-align: center;
        color: white;
    }
    
    .oque-fazemos_content {
        display: flex;
        flex-direction: column;
        justify-content: start;
    }
    .oque-fazemos__item{
        display: flex;
        justify-content: start;
        align-items: center;
        font-size: 1.5rem;
        gap: 8px;
    }
    
    .oque-fazemos__imagem-especialistas {
        width: 100%;
        margin-left: -80px;
    }
    
    .oque-fazemos__frase-de-efeito {
        width: 100%;
    }
    
    .oque-fazemos__imagem-personagens {
        width: 100%;
        margin-bottom: -140px;
    }
    
    .oque-fazemos__imagem-alto-falante {
        height: 40%;
    }
    
    .oque-fazemos__text-container {
        position: absolute;
    
        width: 100%;
        height: 100vh;
        top: 0;
        z-index: 2;
    }
    
    .oque-fazemos__titulo {
        color: #002060;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #000;
        font-size: 58px;
        margin: 0px;
    }
    
    .oque-fazemos__bloco-texto {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .oque-fazemos__negrito {
        color: #002060;
        font-weight: 700;
    }
    
    .oque-fazemos__numero {
        color: #002060;
        font-size: 58px;
        font-weight: 800;
        margin-right: 12px;
    }
    
    .oque-fazemos__texto {
        color: #365d81;
        font-size: 28px;
        font-weight: 600;
        margin: 10px 0px 0px 0px;
    }

    .oque-fazemos__imagem-container {
        display: flex;
    }

    .titulo-2 {
        margin-top: 80px;
    }
}

/* Laptops */
@media (min-width: 1025px) and (max-width: 1367px) {
    .oque-fazemos_title{
        font-family: Karla;
        font-size: 3rem;
        font-weight: 800;
        line-height: 50px;
        padding-inline: 0px 16px;
        text-align: center;
        color: white;
    }
    
    .oque-fazemos_content {
        display: flex;
        flex-direction: column;
        justify-content: start;
    }

    .oque-fazemos__imagens-background {
        position: absolute;
    
        width: 100%;
        height: 110vh;
        top: 0;
    }
    
    .oque-fazemos__imagem-especialistas {
        position: absolute;
        left: 0;
        bottom: 15%;
        height: 70%;
    }
    
    .oque-fazemos__frase-de-efeito {
        position: absolute;
        bottom: 15%;
        right: 24%;
        width: 47%;
    }
    
    .oque-fazemos__imagem-personagens {
        position: absolute;
        top: 0;
        right: 0;
        height: 45%;
    }
    
    .oque-fazemos__imagem-alto-falante {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 40%;
    }
    
    .oque-fazemos__text-container {
        position: absolute;
    
        width: 100%;
        height: 100vh;
        top: 0;
        z-index: 2;
    }
    
    .oque-fazemos__titulo {
        color: #002060;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #000;
        font-size: 58px;
    }
    
    .oque-fazemos__bloco-texto {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .oque-fazemos__negrito {
        color: #002060;
        font-weight: 700;
    }
    
    .oque-fazemos__numero {
        color: #002060;
        font-size: 58px;
        font-weight: 800;
        margin-right: 12px;
    }
    
    .oque-fazemos__texto {
        color: #365d81;
        font-size: 28px;
        font-weight: 600;
        margin: 10px 0px 0px 0px;
    }
    
    .titulo-1 {
        margin: 0;
        margin-left: 15%;
        margin-top: 50px;
    }
    
    .bloco-texto-1 {
        margin-left: 18%;
        width: 47%;
    }
    
    .titulo-2 {
        margin: 0;
        margin-left: 31%;
    }
    .bloco-texto-2 {
        margin-left: 35%;
        width: 45%;
    }
}

/* Monitores desktop */
@media (min-width: 1367px) {
    .oque-fazemos_container {
        height: 110vh;
    }

    .oque-fazemos__imagens-background {
        position: absolute;
    
        width: 100%;
        height: 110vh;
        top: 0;
    }
    
    .oque-fazemos__imagem-especialistas {
        position: absolute;
        left: 0;
        bottom: 5%;
        height: 80%;
    }
    
    .oque-fazemos__frase-de-efeito {
        position: absolute;
        bottom: 8%;
        right: 20%;
        width: 50%;
    }
    
    .oque-fazemos__imagem-personagens {
        position: absolute;
        top: 0;
        right: 0;
        height: 50%;
    }
    
    .oque-fazemos__imagem-alto-falante {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 50%;
    }
    
    .oque-fazemos__text-container {
        position: absolute;
    
        width: 100%;
        height: 90vh;
        top: 0;
        z-index: 2;
    }
    
    .oque-fazemos__titulo {
        color: #002060;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #000;
        font-size: 58px;
    }
    
    .oque-fazemos__bloco-texto {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .oque-fazemos__negrito {
        color: #002060;
        font-weight: 700;
    }
    
    .oque-fazemos__numero {
        color: #002060;
        font-size: 58px;
        font-weight: 800;
        margin-right: 12px;
    }
    
    .oque-fazemos__texto {
        color: #365d81;
        font-size: 28px;
        font-weight: 600;
        margin: 10px 0px 0px 0px;
    }
    
    .titulo-1 {
        margin: 0;
        margin-left: 15%;
        margin-top: 50px;
    }
    
    .bloco-texto-1 {
        margin-left: 15%;
        width: 50%;
    }
    
    .titulo-2 {
        margin: 0;
        margin-left: 31%;
    }
    .bloco-texto-2 {
        margin-left: 40%;
        width: 50%;
    }
}

/* Monitores 4K */
@media (min-width: 1921px) and (max-width: 3840px) {
    .oque-fazemos_container {
        height: 80vh;
    }

    .oque-fazemos__imagens-background {
        position: absolute;
    
        width: 100%;
        height: 80vh;
        top: 0;
    }
    
    .oque-fazemos__imagem-especialistas {
        position: absolute;
        left: 0;
        bottom: 5%;
        height: 80%;
    }
    
    .oque-fazemos__frase-de-efeito {
        position: absolute;
        bottom: 8%;
        right: 20%;
        width: 50%;
    }
    
    .oque-fazemos__imagem-personagens {
        position: absolute;
        top: 0;
        right: 0;
        height: 50%;
    }
    
    .oque-fazemos__imagem-alto-falante {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 50%;
    }
    
    .oque-fazemos__text-container {
        position: absolute;
    
        width: 100%;
        height: 90vh;
        top: 0;
        z-index: 2;
    }
    
    .oque-fazemos__titulo {
        color: #002060;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #000;
        font-size: 58px;
    }
    
    .oque-fazemos__bloco-texto {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .oque-fazemos__negrito {
        color: #002060;
        font-weight: 700;
    }
    
    .oque-fazemos__numero {
        color: #002060;
        font-size: 58px;
        font-weight: 800;
        margin-right: 12px;
    }
    
    .oque-fazemos__texto {
        color: #365d81;
        font-size: 28px;
        font-weight: 600;
        margin: 10px 0px 0px 0px;
    }
    
    .titulo-1 {
        margin: 0;
        margin-left: 15%;
        margin-top: 50px;
    }
    
    .bloco-texto-1 {
        margin-left: 15%;
        width: 50%;
    }
    
    .titulo-2 {
        margin: 0;
        margin-left: 31%;
    }
    .bloco-texto-2 {
        margin-left: 40%;
        width: 50%;
    }
}

/* Telas Ultra-wide */
@media (min-width: 2560px) {}
