/* Smartphones (retratos) */
@media (max-width: 360px) {
    .quem-somos__container{
        width: 100vw;
    
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .quem-somos__content{  
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: center;
        padding: 48px 24px 48px 24px;
        background: rgb(255, 255, 255);
    }
    
    .quem-somos__title{
        color: var(--dark-blue, #002060);
        text-align: center;
        font-family: Karla;
        font-style: normal;
        font-weight: 800;
        font-size: 2.8rem;
    }
    
    .quem-somos__text{
        color: var(--dark-blue, #002060);
        font-family: Karla;
        font-style: normal;
        font-size: 1.12rem;
        text-indent: 2em;
        text-align: justify;
    }
}

@media (min-width: 361px) and (max-width: 522px) {
    .quem-somos__container{
        width: 100vw;
    
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .quem-somos__content{  
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 48px 24px 48px 24px;
        background: rgb(255, 255, 255);
    }
    
    .quem-somos__title{
        color: var(--dark-blue, #002060);
        text-align: center;
        font-family: Karla;
        font-style: normal;
        font-weight: 800;
        font-size: 2.8rem;
    }
    
    .quem-somos__text{
        color: var(--dark-blue, #002060);
        font-family: Karla;
        font-style: normal;
        font-size: 1.12rem;
        text-indent: 2em;
        text-align: justify;
    }
}

/* Smartphones (paisagem) */
@media (min-width: 522px) and (max-width: 640px) {
    .quem-somos__container{
        width: 100vw;
    
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .quem-somos__content{  
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 48px 24px 48px 24px;
        background: rgb(255, 255, 255);
    }
    
    .quem-somos__title{
        color: var(--dark-blue, #002060);
        text-align: center;
        font-family: Karla;
        font-style: normal;
        font-weight: 800;
        font-size: 2.8rem;
    }
    
    .quem-somos__text{
        color: var(--dark-blue, #002060);
        font-family: Karla;
        font-style: normal;
        font-size: 1.12rem;
        text-indent: 2em;
        text-align: justify;
    }
}

/* Tablets (retratos) */
@media (min-width: 641px) and (max-width: 768px) {
    .quem-somos__container{
        width: 100vw;
    
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .quem-somos__content{  
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 48px;
    }
    
    .quem-somos__title{
        color: var(--dark-blue, #002060);
        text-align: center;
        font-family: Karla;
        font-style: normal;
        font-weight: 800;
        font-size: 2.8rem;
    }
    
    .quem-somos__text{
        color: var(--dark-blue, #002060);
        font-family: Karla;
        font-style: normal;
        font-size: 1.12rem;
        text-indent: 2em;
        text-align: justify;
    }
}

/* Tablets (paisagem) */
@media (min-width: 769px) and (max-width: 1024px) {
    .quem-somos__container{
        width: 100vw;
    
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .quem-somos__content{  
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 48px;
        background: rgb(255, 255, 255);
    }
    
    .quem-somos__title{
        color: var(--dark-blue, #002060);
        text-align: center;
        font-family: Karla;
        font-style: normal;
        font-weight: 800;
        font-size: 3rem;
    }
    
    .quem-somos__text{
        color: var(--dark-blue, #002060);
        font-family: Karla;
        font-style: normal;
        text-indent: 2em;
        text-align: justify;
        font-size: 2rem;
        font-weight: 400;
        line-height: 35px;
    }
}

/* Laptops */
@media (min-width: 1025px) and (max-width: 1367px) {
    .quem-somos__container{
        position: relative;
        width: 100vw;
        height: 100vh;
    
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .quem-somos__image-background{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .quem-somos__content-auxiliar-container{
        position: absolute;
        top: 0;
        right: 0;
    
        width: 50vw;
        height: 100%;
    
        display: flex;
        justify-content: center;
        align-items: center;
    
        
        box-sizing: border-box;
        padding-top: 40px;
        padding-right: 40px;
        padding-bottom: 50px;
        border-radius: 30px;
    }
    
    .quem-somos__content{  
        box-sizing: border-box;
        padding: 56px;
        border-radius: 30px;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: stretch;
    
        background: rgba(255, 255, 255, 0.20);
        backdrop-filter: blur(7.5px) grayscale(0.4);
    }
    
    .quem-somos__title{
        color: var(--dark-blue, #002060);
        text-align: center;
        font-family: Karla;
        font-style: normal;
        font-size: 3rem;
        margin: 0px;
    }
    
    .quem-somos__text{
        color: var(--dark-blue, #002060);
        font-family: Karla;
        font-style: normal;
        font-size: 1.12rem;
        text-indent: 2em;
        text-align: justify;
    }
}

/* Monitores desktop */
@media (min-width: 1367px) {
    .quem-somos__container{
        position: relative;
        width: 100vw;
        height: 100vh;
    
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .quem-somos__image-background{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .quem-somos__content-auxiliar-container{
        position: absolute;
        top: 0;
        right: 0;
    
        width: 50vw;
        height: 100%;
    
        display: flex;
        justify-content: center;
        align-items: center;
    
        
        box-sizing: border-box;
        padding-top: 20px;
        padding-right: 40px;
        padding-bottom: 15%;
        border-radius: 50px;
    }
    
    .quem-somos__content{  
        box-sizing: border-box;
        padding-right: 56px;
        padding-left: 56px;
        padding-bottom: 30px ;
        border-radius: 30px;
        height: min-content;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: stretch;
    
        background: rgba(255, 255, 255, 0.20);
        backdrop-filter: blur(7.5px) grayscale(0.4);
    }
    
    .quem-somos__title{
        color: var(--dark-blue, #002060);
        text-align: center;
        font-family: Karla;
        font-style: normal;
        font-size: 3rem;
    }
    
    .quem-somos__text{
        color: var(--dark-blue, #002060);
        font-family: Karla;
        font-style: normal;
        font-size: 1.12rem;
        text-indent: 2em;
        text-align: justify;
    } 
}

/* Monitores 4K */
@media (min-width: 1921px) and (max-width: 3840px) {
   
}

/* Telas Ultra-wide */
@media (min-width: 2560px) {
    .quem-somos__container{
        position: relative;
        width: 100vw;
        height: 100vh;
    
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 700px;
    }
    .quem-somos__content-auxiliar-container{
        position: absolute;
        top: 0;
        right: 0;
    
        width: 50vw;
        height: 100%;
    
        display: flex;
        justify-content: center;
        align-items: center;
    
        
        box-sizing: border-box;
        padding-top: 40px;
        padding-right: 40px;
        padding-bottom: 15%;
        border-radius: 50px;
    }
}
