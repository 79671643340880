/* emailModalStyle.css */

.email-modal__background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Fundo semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 998;
  }
  
  .email-modal__container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    color: #000000;

    padding: 20px;
    border-radius: 24px;
    max-width: 500px;
    height: min-content;
    width: 100%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    position: relative;
    z-index: 999;
  }
  
  .email-modal__superior-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    height: min-content;
  }
  
  .email-modal__title {
    display: flex;
    width: 100%;
    font-size: 2rem;
    font-weight: 600;
    margin: 0px;
    justify-content: center;
  }
  
  .email-modal__close-icon {
    font-size: 1.5rem;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .email-modal__close-icon:hover {
    color: #333;
  }
  
  .email-modal__form {
    padding: 16px;
    background-color: #ededed;
    border-radius: 8px;


    display: flex;
    flex-direction: column;
    overflow: scroll;
    box-sizing: border-box;
  }
  
  .email-modal__form label {
    font-size: 1rem;
    color: #3a3a3a;
    margin-bottom: 5px;
    font-weight: 500;
  }
  
  .email-modal__form input,
  .email-modal__form textarea {
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 0.8rem;
    background-color: #cccccc;
    color: #000000;
    width: 100%;
    box-sizing: border-box;
    transition: border-color 0.3s;
  }
  
  .email-modal__form input:focus,
  .email-modal__form textarea:focus {
    border-color: #333;
    outline: none;
  }
  
  .email-modal__form textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .email-modal__form button[type="submit"] {
    background-color: #007bff;
    color: #fff;
    padding: 12px;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .email-modal__form button[type="submit"]:hover {
    background-color: #0056b3;
  }
  