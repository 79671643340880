.anchor-button{
    display: flex;
    font-size: 24px;
    color: white;
    transition: transform 0.3s ease;
    border-radius: 10px;
    padding: 0px 16px;
}

.anchor-button:hover{
    color: black;
    background-color: white;
    text-decoration: inherit;
    transition: transform 0.3s ease;
    transform: scale(1.05);
}

a {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    color: inherit;
    text-align: inherit;
    text-decoration: inherit;
    cursor: pointer;
    outline: none;
}

.button {
    padding:8px 0px 8px 0px;
    font-size: 24px;
}


/* Smartphones (retratos) */
@media (max-width: 360px) {
    .anchor-button{
        font-size: 24px;
        color: white;
        transition: transform 0.3s ease;
        border-radius: 10px;
        padding: 4px 16px;
        width: 80%;
        justify-content: right;
    }
}

@media (min-width: 361px) and (max-width: 522px) {
    .anchor-button{
        font-size: 24px;
        color: white;
        transition: transform 0.3s ease;
        border-radius: 10px;
        padding: 4px 16px;
        width: 80%;
        justify-content: right;
    }
}

/* Smartphones (paisagem) */
@media (min-width: 522px) and (max-width: 640px) {
    .anchor-button{
        font-size: 24px;
        color: white;
        transition: transform 0.3s ease;
        border-radius: 10px;
        padding: 4px 16px;
        width: 80%;
        justify-content: right;
    }
}

/* Tablets (retratos) */
@media (min-width: 641px) and (max-width: 768px) {
    .anchor-button{
        font-size: 24px;
        color: white;
        transition: transform 0.3s ease;
        border-radius: 10px;
        padding: 4px 16px;
        width: 80%;
        justify-content: right;
    }
}

/* Tablets (paisagem) */
@media (min-width: 769px) and (max-width: 1024px) {
    .anchor-button{
        font-size: 24px;
        color: white;
        transition: transform 0.3s ease;
        border-radius: 10px;
        padding: 4px 16px;
        width: 80%;
        justify-content: right;
    }
}

/* Laptops */
@media (min-width: 1025px) and (max-width: 1367px) {}

/* Monitores desktop */
@media (min-width: 1367px) {}

/* Monitores 4K */
@media (min-width: 1921px) and (max-width: 3840px) {}

/* Telas Ultra-wide */
@media (min-width: 2560px) {}
