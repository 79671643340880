.contato__container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    height: 260px;
    margin:  100px 50px 0px 50px;
    border-radius: 30px;
}

.contato__content {
    border: 6px solid #000919;
    color: white;
    border-radius: 20px ;
    background-color: #DEEBF7;
    color: black;
    padding-left: 150px;
    padding-right: 150px;
    height: 100%;
    gap: 25px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    white-space: nowrap; 
}

.contato__title {
    text-align: center;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px;
}

.contato__buttons-area {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.contato__whats-app-button {
    width: 40px;
    height: 40px;
    color: #000000;
    cursor: pointer;
}

.contato__email-button {
    width: 40px;
    height: 40px;
    color: #000000;
    cursor: pointer;
}

a {
    color: black;
}

.contato__image {
    width: 600px;
    height: 260px;
    border-radius: 20px;
    margin-left: -100px;
    border: 6px solid #000919;
}


/* Smartphones (retratos e paisagem) */

@media (max-width: 640px) {
    .contato__container {
        border: 0px solid rgba(0, 0, 0, 0);
        position: relative;
        width: 100%;
        height: min-content;
        margin: 0px;
    }
    
    .contato__content {
        position: absolute;
        top: 0;
        border: 0px solid rgba(0, 0, 0, 0);
        background-color: rgba(255, 255, 255, 0.332);
        color: black;
        height: 100%;
        gap: 50px;
        z-index: 1;
    }
    
    .contato__title {
        font-size: 48px;
        margin-top: 50px;
    }
    
    .contato__image {
        width: 900px;
        height: 390px;
        border-radius: 0px;
        margin-left: -100px;
        filter: blur(3px);
    }
    
}

/* Smarthphones pequenos (retrato*/
@media (max-width: 361px) {
    .contato__title {
        font-size: 40px;
        margin-top: 50px;
        color: black;

    }
}

/* Tablets (retratos e paisagem) */
@media (min-width: 641px)  and (max-width: 1024px) {
    .contato__container {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        height: 260px;
        margin: 32px;
        border-radius: 30px;
        width: 100%;
        overflow: hidden;
        border: 3px solid black;
    }
    
    .contato__content {
        position: absolute;
        padding-left: 70px;
        padding-right: 70px;
        height: 100%;
        left: 0;
        border: 3px solid #000919;

        gap: 40px
    }
    
    .contato__title {
        text-align: center;
        font-family: Montserrat;
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0px;
    }
    
    .contato__button {
        border-radius: 15px;
        border: 3px solid black;
        padding: 20px 40px 20px 40px;
        text-align: center;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        background-color: #3FFF25;
        cursor: pointer;
        transition: transform 0.5s ease;
    }
    
    .contato__button:hover {
        transition: transform 0.5s ease;
        transform: scale(1.2);
    }
    
    a {
        color: black;
    }
    
    .contato__image {
        position: absolute;
        right: -80px;
        width: 600px;
        height: 260px;
        border-radius: 20px;
        margin-right: -50px;
        border: 6px solid #000919;
    }
    
}

/* Laptops */
@media (min-width: 1025px) {
    .contato__button {
        transition: transform 0.4s ease;
    }
    
    .contato__button:hover {
        transition: transform 0.4s ease;
        transform: scale(1.1);
    }
}