.nossos-parceiros__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.nossos-parceiros__title {
    font-family: 'Karla';
    font-size: 3rem;
    font-weight: 800;
    text-align: center;
    color: #002060;
}


.d{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.nossos-parceiros__caroussel {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 200px
}

.nossos-parceiros__imagens-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 16px
}

/* Smartphones (retratos) */
@media (max-width: 360px) {
    .nossos-parceiros__container {
        width: 100%;
    }
    .nossos-parceiros__title {
        font-size: 2.5rem;
    }
}

@media (min-width: 361px) and (max-width: 522px) {
    .nossos-parceiros__container {
        width: 100%;
    }
    .nossos-parceiros__title {
        font-size: 2.5rem;
    }
}

/* Smartphones (paisagem) */
@media (min-width: 522px) and (max-width: 640px) {
    .nossos-parceiros__container {
        width: 100%;
    }
    .nossos-parceiros__title {
        font-size: 2.5rem;
    }
}

/* Tablets (retratos) */
@media (min-width: 641px) and (max-width: 768px) {
    .nossos-parceiros__container {
        width: 100%;
    }
    .nossos-parceiros__title {
        font-size: 2.5rem;
    }
}

/* Tablets (paisagem) */
@media (min-width: 769px) and (max-width: 1024px) {}

/* Laptops */
@media (min-width: 1025px) and (max-width: 1367px) {
    .nossos-parceiros__caroussel {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

/* Monitores desktop */
@media (min-width: 1367px) {}

/* Monitores 4K */
@media (min-width: 1921px) and (max-width: 3840px) {}

/* Telas Ultra-wide */
@media (min-width: 2560px) {}
