.mobile-menu__icon {
    width: 12vw;
    height: 12vw;
    max-width: 60px;
    max-height: 60px;
}

.mobile-menu__container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.navbar-anchor-buttons__mobile {
    position: absolute;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    gap: 24px;
    
    height: min-content;
    background-color: rgba(0, 0, 0, 0.95);

    overflow: scroll;
}