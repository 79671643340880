.nosso-produto__botao-amostra {
    border-radius: 24px;
    padding: 16px 40px 16px 40px;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: #002060;
    cursor: pointer;
    justify-self: center;
    color: white;
}

@media (max-width: 1024px) {
    .nosso-produto__botao-amostra {
        box-sizing: border-box;
        width: 90%;
    }
}

@media (min-width: 1025px) {
    .nosso-produto__botao-amostra {
        transition: transform 0.4s ease;
    }

    .nosso-produto__botao-amostra:hover {
        transition: transform 0.4s ease;
        transform: scale(1.1);
}
}