/* Smartphones (retratos) */
@media (max-width: 360px) {
    .hero-section__container {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }
    
    .hero-section__background {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100vh;
        background-color: #00206039;
    }
    
    .hero-section__background-image {
        height: 100vh;
        filter: blur(5px) grayscale(0.7);
    }
    
    .hero-section__content {
        z-index: 2;
        position: absolute;
        top: 30vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .hero-section_phone-frame {
        width: 0px;
        max-height: 480px;
    }
    .hero-section__header-call {
        width: 90vw;  
        color: white;
        font-family: Lato;
        font-size: 44px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

@media (min-width: 361px) and (max-width: 522px) {
    .hero-section__container {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }
    
    .hero-section__background {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100vh;
        background-color: #00206039;
    }
    
    .hero-section__background-image {
        height: 100vh;
        filter: blur(5px) grayscale(0.7);
    }
    
    .hero-section__content {
        z-index: 2;
        position: absolute;
        top: 30vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .hero-section_phone-frame {
        width: 0px;
        max-height: 480px;
    }
    .hero-section__header-call {
        width: 90vw;  
        color: white;
        font-family: Lato;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

/* Smartphones (paisagem) */
@media (min-width: 522px) and (max-width: 640px) {
    .hero-section__container {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }
    
    .hero-section__background {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100vh;
        background-color: #00206039;
    }
    
    .hero-section__background-image {
        height: 100vh;
        filter: blur(5px) grayscale(0.7);
    }
    
    .hero-section__content {
        z-index: 2;
        position: absolute;
        top: 40vh;
        background-color: rgba(255, 255, 255, 0.5);
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .hero-section_phone-frame {
        width: 0px;
        max-height: 480px;
    }
    
    .hero-section__header-call {
        width: 80vw;  
        color: var(--dark-blue, #002060);
        font-family: Lato;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    
}

/* Tablets (retratos) */
@media (min-width: 641px) and (max-width: 768px) {
    .hero-section__container {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    
    .hero-section__background {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100vh;
        background-color: #00206044;
    }
    
    .hero-section__background-image {
        margin-left: 380px;
        height: 100vh;
        filter: blur(5px) grayscale(0.7);
    }
    
    .hero-section__content {
        z-index: 2;
        position: absolute;
        background-color: rgba(255, 255, 255, 0.5);
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .hero-section_phone-frame {
        width: 50%;
        max-height: 480px;
    }
    
    .hero-section__header-call {
        width: 340px;  
        color: var(--dark-blue, #002060);
        font-family: Lato;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    
}

/* Tablets (paisagem) */
@media (min-width: 769px) and (max-width: 1024px) {
    .hero-section__container {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    
    .hero-section__background {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100vh;
        background-color: #00206044;
    }
    
    .hero-section__background-image {
        margin-left: 380px;
        height: 100vh;
        filter: blur(5px) grayscale(0.7);
    }
    
    .hero-section__content {
        z-index: 2;
        position: absolute;
        background-color: rgba(255, 255, 255, 0.5);
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .hero-section_phone-frame {
        width: 50%;
        max-height: 480px;
    }
    
    .hero-section__header-call {
        width: 340px;  
        color: var(--dark-blue, #002060);
        font-family: Lato;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    
}

/* Laptops */
@media (min-width: 1025px) and (max-width: 1367px) {
    .hero-section__container {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
    }
    
    .hero-section__background {
        width: 100%;
        margin-top: 53px;
        height: 800px;
        background-color: #ffffff;
    }
    
    .hero-section__background-image {
        width: 100%;
        filter: blur(5px) grayscale(0.5);
    }
    
    .hero-section__content {
        z-index: 2;
        position: absolute;
        top: 350px;
        background-color: rgba(216, 216, 216, 1);
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: right;
        align-items: center;
    }
    
    .hero-section_phone-frame {
        width: 40%;
        max-height: 480px;
    }

    .hero-section__heards-container {
        width: 54%;  
    }
    
    .hero-section__header-call {
        width: 100%;  
        color: var(--dark-blue, #002060);
        font-family: Lato;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 0.1em;
        margin: 0px;
    }

    .hero-section__sub-header-call {
        width: 100%;  
        color: var(--dark-blue, #002060);
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.05em;
    }
    
}

/* Monitores desktop */
@media (min-width: 1367px) {
    .hero-section__container {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
    }
    
    .hero-section__background {
        width: 100%;
        margin-top: 53px;
        height: 100vh;
        background-color: #ffffff;

        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .hero-section__background-image {
        height: 100vh;
        width: 100%;
        filter: blur(5px) grayscale(0.5);
    }
    
    .hero-section__content {
        z-index: 2;
        background-color: rgba(216, 216, 216, 1);
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: right;
        align-items: center;
        justify-content: center;
        padding-right: 20px;
        box-sizing: border-box;
    }
    
    .hero-section_phone-frame {
        width: 35%;
        max-height: 480px;
    }

    .hero-section__heards-container {
        width: 54%;  
    }
    
    .hero-section__header-call {
        width: 100%;
        color: #002060;
        font-family: Lato;
        font-size: 28px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 0.1em;
        margin: 0px;
    }

    .hero-section__sub-header-call {
        width: 90%;  
        color: var(--dark-blue, #002060);
        font-family: Lato;
        font-size: 23px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.05em;
    }
    
}

/* Monitores 4K */
@media (min-width: 1921px) and (max-width: 3840px) {
    /* Estilos específicos para monitores 4K */
}

/* Telas Ultra-wide */
@media (min-width: 2560px) {
    /* Estilos específicos para monitores ultra-wide */
}
