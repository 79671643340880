.logo-parceiro__anchor{
    width: min-content;
}

.logo-parceiro {
    max-width: 280px;
    max-height: 150px;
}

@media (min-width: 1025px) {
    .logo-parceiro__anchor{
        padding: 0px 50px;
    }
}