.nosso-produto__container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.nosso-produto__title {
    width: auto;
    font-family: 'Karla';
    text-align: center;
    color: #002060;
}


.nosso-produto__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nosso-produto__text {
    width: 640px;
    font-family: Karla;
    text-align: left;
    color: #002060;
    margin-top: 0px;
    text-align: justify;
    text-indent: 2em;
}

.nosso-produto__imagens-container {
    display: flex;
    flex-wrap: wrap;
}

.nosso-produto__text-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    justify-content: space-between;
}

@media (max-width: 360px) {
    

    .nosso-produto__title {
        font-size: 2.4rem;
        font-weight: 800;
        color: #002060;
        padding: 16x;
    }


    .nosso-produto__content {
        flex-direction: column;
        width: 100%;
    }

    .nosso-produto__text {
        width: auto;
        padding: 0px 24px;
        font-size: 1.12rem;
        font-weight: 400;
        line-height: 35px;
        margin: 32px 0px;
    }

    .nosso-produto__imagens-container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        overflow: hidden;
    }

    .nosso-produto__imagem {
        height: 160px;
        width: 290px;
    }
}

/* Smartphones (paisagem) */
@media (min-width: 361px) and (max-width: 640px) {

    .nosso-produto__title {
        font-size: 2.8rem;
        font-weight: 800;
        color: #002060;
        padding: 16x;
    }


    .nosso-produto__content {
        flex-direction: column;
        width: 100%;
    }

    .nosso-produto__text {
        width: auto;
        padding: 0px 24px;
        font-size: 1.12rem;
        font-weight: 400;
        line-height: 35px;
        margin: 32px 0px;
    }

    .nosso-produto__imagens-container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        overflow: hidden;
    }

    .nosso-produto__imagem {
        height: 160px;
        width: 290px;
    }
}

/* Tablets (retratos) */
@media (min-width: 641px) and (max-width: 768px) {
    .nosso-produto__title {
        font-size: 5rem;
        font-weight: 800;
        color: #002060;
        padding: 16x;
    }


    .nosso-produto__content {
        flex-direction: column;
        width: 100%;
    }

    .nosso-produto__text {
        width: auto;
        padding: 0px 24px;
        font-size: 1.12rem;
        font-weight: 400;
        line-height: 35px;
        margin: 32px 0px;
    }

    .nosso-produto__imagens-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        overflow: hidden;
    }

    .nosso-produto__imagem {
        height: 160px;
        width: 290px;
    }
}

/* Tablets (paisagem) */
@media (min-width: 769px) and (max-width: 1024px) {
    .nosso-produto__title {
        font-size: 3rem;
        font-weight: 800;
        color: #002060;
        padding: 16x;
    }


    .nosso-produto__content {
        flex-direction: column;
        width: 100%;
    }

    .nosso-produto__text {
        width: auto;
        padding: 0px 24px;
        font-size: 2rem;
        font-weight: 400;
        line-height: 35px;
    }

    .nosso-produto__imagens-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        padding-bottom: 40px;
    }

    .nosso-produto__imagem {
        height: 160px;
        width: 290px;
    }
}

/* Laptops */
@media (min-width: 1025px) and (max-width: 1366px) {
    .nosso-produto__title {
        font-size: 3rem;
        font-weight: 800;
        text-align: center;
        color: #002060;
    }


    .nosso-produto__content {
        width: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .nosso-produto__text-container{
        width: 500px;    
    }

    .nosso-produto__text {
        width: auto;
        font-size: 1.12rem;
        font-weight: 400;
    }

    .nosso-produto__imagens-container {
        display: flex;
        flex-wrap: wrap;
        width: 640px;
        gap: 10px;
    }

    .nosso-produto__imagem {
        height: 160px;
        width: 290px;
    }

    .nosso-produto__text-container{
        gap: 20px;
    }
}
/* Monitores desktop */
@media (min-width: 1367px) and (max-width: 1920px) {
    .nosso-produto__title {
        font-size: 3rem;
        font-weight: 800;
        text-align: center;
        color: #002060;
    }


    .nosso-produto__content {
        width: auto;
        display: flex;
        justify-content: space-between;
    }

    .nosso-produto__text {
        width: 600px;
        font-size: 1.12rem;
        font-weight: 400;
    }

    .nosso-produto__imagens-container {
        display: flex;
        flex-wrap: wrap;
        width: 640px;
        gap: 10px;
    }

    .nosso-produto__imagem {
        height: 160px;
        width: 290px;
    }

    .nosso-produto__text-container{
        gap: 20px;
    }
}

/* Monitores 4K */
@media (min-width: 1921px) and (max-width: 3840px)  {
    .nosso-produto__title {
        font-size: 3rem;
        font-weight: 800;
        text-align: center;
        color: #002060;
    }


    .nosso-produto__content {
        width: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .nosso-produto__text {
        width: 400px;
        font-size: rem;
        font-weight: 400;
        line-height: 35.07px;
    }

    .nosso-produto__imagens-container {
        display: flex;
        flex-wrap: wrap;
        width: 640px;
        gap: 10px;
    }

    .nosso-produto__imagem {
        height: 140px;
        width: 252px;
    }

    .nosso-produto__text-container{
        gap: 20px;
    }
}

/* Telas Ultra-wide */
@media (min-width: 2560px)  {
    .nosso-produto__title {
        font-size: 3rem;
        font-weight: 800;
        text-align: center;
        color: #002060;
    }


    .nosso-produto__content {
        width: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .nosso-produto__text {
        width: 400px;
        font-size: rem;
        font-weight: 400;
        line-height: 35.07px;
    }

    .nosso-produto__imagens-container {
        display: flex;
        flex-wrap: wrap;
        width: 640px;
        gap: 10px;
    }

    .nosso-produto__imagem {
        height: 140px;
        width: 252px;
    }

    .nosso-produto__text-container{
        gap: 20px;
    }
}