.footer-container{
    height: min-content;
    width: 100%;
    background-color: black;
}

.footer-upper-part{
    width: 100%;
    padding: 16px 200px;
    display: flex;
    width: auto;
    justify-content: space-between;
}

.icons-container{
    display: flex;
    gap: 20px;
}

.icon-link {
    width: 40px;
    height: 40px;
}

.organization-infos{
    width:max-content;
}

.info-text{
    color: white;
    font-size: 16px;
    margin: 5px;
}

.Logo{
    color:white
}

.footer-lower-part{
    display: flex;
    justify-content: center;
    background-color: #111111;
    color: white;
}

/* Smartphones (retratos) */
@media (max-width: 360px) {
    .footer-upper-part{
        width: auto;
        margin: 8px 16px 8px 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0px;
    }
    
    .contact {
        display: flex;
        width: auto;
        justify-content: space-between;
        align-items: center;
    }
    
    .icons-container{
        display: flex;
        width: min-content;
        gap: 8px;
    }
    
    .icon-link {
        width: 40px;
        height: 40px;
    }
    
    .organization-infos{
        display: flex;
        justify-content: center;
        width:100%;
    }
    
    .info-text{
        color: white;
        font-size: 16px;
        margin: 5px;
    }
    
    .Logo{
        color:white
    }
    
    .footer-lower-part{
        display: flex;
        justify-content: center;
        background-color: #111111;
        color: white;
    }
    
}

@media (min-width: 361px) and (max-width: 522px) {
    .footer-upper-part{
        width: auto;
        margin: 8px 16px 8px 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0px;
    }
    
    .contact {
        display: flex;
        width: auto;
        justify-content: space-between;
        align-items: center;
    }
    
    .icons-container{
        display: flex;
        width: min-content;
        gap: 8px;
    }
    
    .icon-link {
        width: 40px;
        height: 40px;
    }
    
    .organization-infos{
        display: flex;
        justify-content: center;
        width:100%;
    }
    
    .info-text{
        color: white;
        font-size: 16px;
        margin: 5px;
    }
    
    .Logo{
        color:white
    }
    
    .footer-lower-part{
        display: flex;
        justify-content: center;
        background-color: #111111;
        color: white;
    }
    
}

/* Smartphones (paisagem) */
@media (min-width: 522px) and (max-width: 640px) {
    .footer-upper-part{
        width: auto;
        margin: 8px 16px 8px 16px;
        display: flex;
        flex-direction: column;
        padding: 0px;
    }
    
    .contact {
        display: flex;
        width: auto;
        justify-content: space-between;
        align-items: center;
    }
    
    .icons-container{
        display: flex;
        width: min-content;
        gap: 8px;
    }
    
    .icon-link {
        width: 40px;
        height: 40px;
    }
    
    .organization-infos{
        width:100%;
    }
    
    .info-text{
        color: white;
        font-size: 16px;
        margin: 5px;
    }
    
    .Logo{
        color:white
    }
    
    .footer-lower-part{
        display: flex;
        justify-content: center;
        background-color: #111111;
        color: white;
    }
    
}

/* Tablets (retratos) */
@media (min-width: 641px) and (max-width: 768px) {
    .footer-upper-part{
        width: auto;
        margin: 8px 16px 8px 16px;
        display: flex;
        flex-direction: column;
        padding: 0px;
    }
    
    .contact {
        display: flex;
        width: auto;
        justify-content: space-between;
        align-items: center;
    }
    
    .icons-container{
        display: flex;
        width: min-content;
        gap: 8px;
    }
    
    .icon-link {
        width: 40px;
        height: 40px;
    }
    
    .organization-infos{
        width:100%;
    }
    
    .info-text{
        color: white;
        font-size: 16px;
        margin: 5px;
    }
    
    .Logo{
        color:white
    }
    
    .footer-lower-part{
        display: flex;
        justify-content: center;
        background-color: #111111;
        color: white;
    }
    
}

/* Tablets (paisagem) */
@media (min-width: 769px) and (max-width: 1024px) {
    .footer-upper-part{
        width: auto;
        margin: 8px 16px 8px 16px;
        display: flex;
        flex-direction: column;
        padding: 0px;
    }
    
    .contact {
        display: flex;
        width: auto;
        justify-content: space-between;
        align-items: center;
    }
    
    .icons-container{
        display: flex;
        width: min-content;
        gap: 8px;
    }
    
    .icon-link {
        width: 40px;
        height: 40px;
    }
    
    .organization-infos{
        width:100%;
    }
    
    .info-text{
        color: white;
        font-size: 16px;
        margin: 5px;
    }
    
    .Logo{
        color:white
    }
    
    .footer-lower-part{
        display: flex;
        justify-content: center;
        background-color: #111111;
        color: white;
    }
    
}

/* Laptops */
@media (min-width: 1025px) and (max-width: 1367px){
    .footer-upper-part{
        width: auto;
        margin: 8px 16px 8px 16px;
        display: flex;
        flex-direction: column;
    }
    
    .contact {
        display: flex;
        width: auto;
        justify-content: space-between;
        align-items: center;
    }
    
    .icons-container{
        display: flex;
        width: min-content;
        gap: 8px;
    }
    
    .icon-link {
        width: 40px;
        height: 40px;
    }
    
    .organization-infos{
        width:100%;
    }
    
    .info-text{
        color: white;
        font-size: 16px;
        margin: 5px;
    }
    
    .Logo{
        color:white
    }
    
    .footer-lower-part{
        display: flex;
        justify-content: center;
        background-color: #111111;
        color: white;
    }
    
}

/* Monitores desktop */
@media (min-width: 1367px) {
    .footer-upper-part{
        width: auto;
        margin: 8px 16px 8px 16px;
        display: flex;
        flex-direction: column;

    }
    
    .contact {
        display: flex;
        width: auto;
        justify-content: space-between;
        align-items: center;
    }
    
    .icons-container{
        display: flex;
        width: min-content;
        gap: 8px;
    }
    
    .icon-link {
        width: 40px;
        height: 40px;
    }
    
    .organization-infos{
        width:100%;
    }
    
    .info-text{
        color: white;
        font-size: 16px;
        margin: 5px;
    }
    
    .Logo{
        color:white
    }
    
    .footer-lower-part{
        display: flex;
        justify-content: center;
        background-color: #111111;
        color: white;
    }
    
}

/* Monitores 4K */
@media (min-width: 1921px) and (max-width: 3840px) {
    .footer-upper-part{
        width: auto;
        margin: 8px 16px 8px 16px;
        display: flex;
        flex-direction: column;
    }
    
    .contact {
        display: flex;
        width: auto;
        justify-content: space-between;
        align-items: center;
    }
    
    .icons-container{
        display: flex;
        width: min-content;
        gap: 8px;
    }
    
    .icon-link {
        width: 40px;
        height: 40px;
    }
    
    .organization-infos{
        width:100%;
    }
    
    .info-text{
        color: white;
        font-size: 16px;
        margin: 5px;
    }
    
    .Logo{
        color:white
    }
    
    .footer-lower-part{
        display: flex;
        justify-content: center;
        background-color: #111111;
        color: white;
    }
    
}

/* Telas Ultra-wide */
@media (min-width: 2560px) {
    .footer-upper-part{
        width: auto;
        margin: 8px 16px 8px 16px;
        display: flex;
        flex-direction: column;
    }
    
    .contact {
        display: flex;
        width: auto;
        justify-content: space-between;
        align-items: center;
    }
    
    .icons-container{
        display: flex;
        width: min-content;
        gap: 8px;
    }
    
    .icon-link {
        width: 40px;
        height: 40px;
    }
    
    .organization-infos{
        width:100%;
    }
    
    .info-text{
        color: white;
        font-size: 16px;
        margin: 5px;
    }
    
    .Logo{
        color:white
    }
    
    .footer-lower-part{
        display: flex;
        justify-content: center;
        background-color: #111111;
        color: white;
    }
    
}
