.beneficios__title {
    color: #FFF;
    text-align: center;
    font-family: Karla;
    font-style: normal;
    margin-bottom: 50px;
}

.beneficios__content {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    gap: 16px;
}

/* Smartphones (retratos) */
@media (max-width: 360px) {
    .beneficios__container {
        width: auto;
    }
    .beneficios__title {
        font-size: 2.5rem;
        font-weight: 800;
        line-height: normal;
    }
}

@media (min-width: 361px) and (max-width: 522px) {
    .beneficios__container {
        width: auto;
        margin: 0px 32px  32px  32px;
    }
    .beneficios__title {
        font-size: 2.5rem;
        font-weight: 800;
        line-height: normal;
    }
}

/* Smartphones (paisagem) */
@media (min-width: 522px) and (max-width: 640px) {
    .beneficios__container {
        width: auto;
        margin: 0px 32px 32px 32px;
    }
    .beneficios__title {
        font-size: 2.5rem;
        font-weight: 800;
        line-height: normal;
    }
}

/* Tablets (retratos) */
@media (min-width: 641px) and (max-width: 768px) {
    .beneficios__container {
        width: auto;
        margin: 0px 32px 32px 32px;
    }
    .beneficios__title {
        font-size: 2.5rem;
        font-weight: 800;
        line-height: normal;
    }
}

/* Tablets (paisagem) */
@media (min-width: 769px) and (max-width: 1024px) {
    .beneficios__container {
        width: auto;
        margin: 0px 200px 50px 200px;
    }
    .beneficios__title {
        font-size: 3rem;
        font-weight: 800;
        line-height: normal;
    }
}

/* Laptops */
@media (min-width: 1025px) and (max-width: 1367px) {
    .beneficios__container {
        width: 80%;
        margin: 0px 200px;
    }
    .beneficios__title {
        font-size: 3rem;
        font-weight: 800;
        line-height: normal;
    }
}

/* Monitores desktop */
@media (min-width: 1367px) {
    .beneficios__container {
        width: 65%;
        margin: 0px 200px;
    }
    .beneficios__title {
        font-size: 3rem;
        font-weight: 800;
        line-height: normal;
    }
}

/* Monitores 4K */
@media (min-width: 1921px) and (max-width: 3840px) {
    .beneficios__container {
        width: auto;
        margin: 0px 200px 50px 200px;
    }
    .beneficios__title {
        font-size: 4.5rem;
        font-weight: 800;
        line-height: normal;
    }
}

/* Telas Ultra-wide */
@media (min-width: 2560px) {
    .beneficios__container {
        width: auto;
        margin: 0px 200px 50px 200px;
    }
    .beneficios__title {
        font-size: 3rem;
        font-weight: 800;
        line-height: normal;
    }
}
