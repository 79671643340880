.log{
    position: fixed;
    z-index: 1;
}

.navbar-container{
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100vw;
}

.navbar-wraper {
    display: flex;
    width: auto;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.95);
    color: white;
    padding: 8px 40px 8px 40px;
}

.navbar-anchor-buttons{
    display: flex;
    align-items: center;
    gap: 50px;
}

/* Smartphones (retratos) */
@media (max-width: 360px) {
    .navbar-container{
        position: fixed;
        top: 0;
        z-index: 99;
        width: 100vw;
    }

    .navbar-wraper {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        background-color: rgba(0, 0, 0, 0.95);
        color: white;
        padding: 8px 16px 8px 16px;
    }
}

/* Smartphones (retratos - intervalo) */
@media (min-width: 361px) and (max-width: 522px) {
    .navbar-wraper {
        display: flex;
        justify-content: space-between;
        background-color: rgba(0, 0, 0, 0.95);
        color: white;
        padding: 8px 16px 8px 16px;
    }
}

/* Smartphones (paisagem) */
@media (min-width: 522px) and (max-width: 640px) {
    /* Estilos específicos para smartphones em paisagem */
}

/* Tablets (retratos) */
@media (min-width: 641px) and (max-width: 768px) {
    /* Estilos específicos para tablets em retrato */
}

/* Tablets (paisagem) */
@media (min-width: 769px) and (max-width: 1024px) {
}

/* Laptops */
@media (min-width: 1025px) {
    .navbar-container{
        position: fixed;
        top: 0;
        z-index: 99;
        width: 100%;
    }
    
    .navbar-wraper {
        display: flex;
        justify-content: space-between;
        background-color: rgba(0, 0, 0, 0.95);
        color: white;
        padding: 8px 40px 8px 40px;
    }
    
    .navbar-anchor-buttons{
        display: flex;
        align-items: center;
        gap: 24px;
    }
}

/* Monitores desktop */
@media (min-width: 1367px) and (max-width: 1920px) {
    /* Estilos específicos para monitores desktop */
}

/* Monitores 4K */
@media (min-width: 1921px) and (max-width: 3840px) {
    /* Estilos específicos para monitores 4K */
}

/* Telas Ultra-wide */
@media (min-width: 2560px) {
    /* Estilos específicos para monitores ultra-wide */
}
