.section{
    width: 100vw;
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: 50px 0px 150px 0px;
    height: min-content;
}

.oque-fazemos-section {
    padding: 0px 0px 0px 0px;
}

/* Smartphones (retratos) */
@media (max-width: 360px) {
    .section{
        display: flex;
        justify-content: center;
        padding: 16px 0px 16px 0px;
        padding: 50px 0px 50px 0px;
        overflow: hidden;
    }
}

/* Smartphones (paisagem) */
@media (min-width: 361px) and (max-width: 640px) {
    .section{
        display: flex;
        justify-content: center;
        padding: 50px 0px 50px 0px;
        overflow: hidden;
    }

    .padding {
        padding: 48px 0px 48px 0px ;
    }
}

/* Tablets (retratos) */
@media (min-width: 641px) and (max-width: 768px) {
    .section{
        display: flex;
        justify-content: center;
        padding: 50px 0px 50px 0px;
        overflow: hidden;
    }

    .padding {
        padding: 16px 0px 16px 0px;
    }
}

/* Tablets (paisagem) */
@media (min-width: 769px) and (max-width: 1024px) {
    .section{
        display: flex;
        justify-content: center;
        overflow: hidden;
    padding: 50px 0px 50px 0px;

    }
    
    .padding {
        padding: 48px 0px 48px 0px ;
    }
}

/* Laptops */
@media (min-width: 1025px) and (max-width: 1366px) {
    .section{
        display: flex;
        justify-content: center;
        overflow: hidden;
    }
    
    .padding {
        padding: 50px 0px 150px 0px;
    }
}

/* Monitores desktop */
@media (min-width: 1367px) and (max-width: 1920px) {
    .section{
        display: flex;
        justify-content: center;
        overflow: hidden;
    }
    
    .padding {
        padding: 50px 0px 150px 0px;
    }
}

/* Monitores 4K */
@media (min-width: 1921px) and (max-width: 3840px) {
    .section{
        display: flex;
        justify-content: center;
        overflow: hidden;
    }
    
    .padding {
        padding: 50px 0px 150px 0px;
    }
}

/* Telas Ultra-wide */
@media (min-width: 2560px) {
    .section{
        display: flex;
        justify-content: center;
        overflow: hidden;
    }
        
    .padding {
        padding: 50px 0px 150px 0px;
    }
}
